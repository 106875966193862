import React from 'react';
import BillingAddress from '../../components/Account/BillingAddress';
import ContextProvider from '../../components/ContextProvider';
import PrivateRoute from '../../components/PrivateRoute';

const BillingAddressPage = ({ localeCode }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <PrivateRoute path="/account/billing-address" component={BillingAddress} />
  </ContextProvider>
);

export default BillingAddressPage;
